@import "../../assets/scss/variables";

.emblemcard-box {
  background-color: #fff !important;
  border-radius: $borderradius-default !important;
  text-align: center;
  height: 100%;
  position: relative !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3) !important;

  &.active {
    background-color: #fff !important;
  }

  .iconbutton-info {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .emblemcard-badge {
    width: 75%;
  }
}

.infoemblem-tooltip {
  border-radius: $borderradius-default !important;
  padding: 0px !important;
  width: 252px !important;
  text-align: center !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3) !important;

  .infoemblem-title {
    border-top-left-radius: $borderradius-default !important;
    border-top-right-radius: $borderradius-default !important;
  }
}
