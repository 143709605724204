
@import '../../assets/scss/variables';

.navbar-vertical {
    .navbar-header {
        position: relative;

        .navbar-brand-link {
            height: 100%;
            width: 100%;
            max-width: 95px;
            display: block;
            text-align: center;
            margin: 0 auto;
        }

        .navbar-brand-img {
            margin: 0 auto;
            max-width: unset;
            width: 100%;
        }
    }

    .nav-item {
        .nav-link {
            font-size: 12px !important;

            .icon {
                width: 20px;
                font-size: 20px;
                min-width: unset;
                margin-right: 8px;
            }

            &.active {
                color: $color-primary !important;

                &::before {
                    content: '';
                    top: 15% !important;
                    left: -5px !important;
                    height: 70%;
                    width: 8px;
                    border-radius: 20px;
                    background-color: $color-primary;
                }
            }
        }
    }
}


@media screen and (max-width: 767px) {
    .navbar-vertical {
        .navbar-header {    
            .navbar-toggler {
                position: absolute;
                left: 0;
                top: 17px; 
            }
    
            .navbar-triggers {
                position: absolute;
                right: 0px;
                top: 5px;

                &.is-not-store {
                    top: 22px;
                }
            }
        }

        .nav-item {
            .nav-link {
                &.active {
                    &::before {
                        left: 0px !important;
                        width: 4px;
                    }
                }
            }
        }
    }
}