
@import '../../assets/scss/variables';


.textfield-default {
    // Input
    .MuiOutlinedInput-root {
        line-height: 16px !important;
        border-radius: $component-textfield-default-border-radius !important;
    }

    &:not(.multiline) {
        .MuiOutlinedInput-input {
            height: 16px !important;
        }
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $component-textfield-default-border-color-actived !important;
    }


    // Label
    .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
        line-height: initial !important;
        top: -2px !important;
        font-size: 0.85rem !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: $component-textfield-default-border-color-actived !important;
    }


    // HelperText
    .MuiFormHelperText-root {
        font-size: 0.65rem !important;
        margin-top: 2px !important;
        margin-right: 8px !important;
        margin-left: 8px !important;
    }



    // White theme
    &.white-theme {

        // Input
        .MuiOutlinedInput-root {
            color: #fff;
            
            &.Mui-focused .MuiOutlinedInput-notchedOutline,
            .MuiOutlinedInput-notchedOutline {
                border-color: rgba(255, 255, 255, 0.7) !important;
            }
        }


        // Label
        .MuiFormLabel-root {
            color: rgba(255, 255, 255, 0.9) !important;

            &.Mui-focused {
                color: rgba(255, 255, 255, 0.9) !important;
            }
        }
    }
}
