.fileContainer {
  overflow: hidden;
  position: relative;
}

.fileContainer i {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.round {
  border-radius: 50%;
  padding: 10px;
}

.fileContainer [type='file'] {
  cursor: inherit;
  display: block;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.table-select tbody tr:hover {
  background-color: lightgray !important;
}

img.attachment-form {
  position: relative;
  width: 100%;
  display: inline-block;
}

@media (min-width: 768px) {
  img.attachment-form {
    position: relative;
    width: 25% !important;
    display: inline-block;
  }
}
