

@import '../../assets/scss/variables';

.button-default {
    border-radius: $borderradius-default !important;
    height: 48px !important;
    line-height: 28px !important;
    opacity: 0.95;
    transition: 0.4s;
    background: $color-grey-1 !important;
    color: #000 !important;
    padding: 0px 40px !important;
    text-transform: initial !important;

    // Margins/Paddings
    &.padding-none {
        padding: 0px !important;
    }


    // Sizes
    &.small {
        height: 36px !important;
        line-height: 16px !important;
    }


    // Colors
    &.background-primary {
        background: $color-primary !important;
        color: #fff !important;
    }

    &.background-blue {
        background: $color-blue !important;
        color: #fff !important;
    }

    &.background-red {
        background: $color-red !important;
        color: #fff !important;
    }

    &.background-green {
        background: $color-green !important;
        color: #fff !important;
    }

    &.background-cyan {
        background: $color-cyan !important;
        color: #fff !important;
    }


    // Props
    &:hover {
        opacity: 1;
        transition: 0.4s;
    }

    &:disabled {
        background-color: #e0e0e0 !important;
        color: #000 !important;
        opacity: 0.5;
        transition: 0.4s;
    }
}
