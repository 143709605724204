
@import '../../assets/scss/variables';

.filters-container {
    align-items: center;
    margin-bottom: 72px;

    .triggers-container {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 224px;
    }

    .box-buttons {
        display: flex;
        justify-content: flex-end;

        .category-button {
            margin-left: 12px;
            min-width: 120px;
            background-color: #fff;
            color: $color-grey-4;
            text-transform: initial;
            border-radius: 20px;
            font-size: 12px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
            transition: 0.2s;
    
            .category-icon {
                margin-right: 4px;
                font-size: 24px;
            }
    
    
            &.selected {
                color: #fff;
                background-color: $color-primary;
            }
        }
    }
}


@media screen and (max-width: $screen-size-lg) {
    .filters-container {
        .box-buttons {
            flex-direction: column;
    
            .category-button {
                margin-left: 0px;
                min-width: 45%;
                margin-bottom: 8px;
            }
        }
    }
}