
@import '../../assets/scss/variables';


.selectfield-default {
    // Select
    .MuiOutlinedInput-root {
        line-height: 16px !important;
        border-radius: $component-selectfield-default-border-radius !important;
    }

    .MuiOutlinedInput-input {
        display: flex !important;
        align-items: center !important;

        &.MuiSelect-select {
            line-height: unset !important;
            min-height: unset !important;
            height: 16px;
        }
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $component-selectfield-default-border-color-actived !important;
    }


    // Label
    .MuiInputLabel-root {
        &.Mui-focused {
            color: $component-selectfield-default-border-color-actived !important;
        }

        &:not(.MuiInputLabel-shrink) {
            line-height: initial !important;
            top: -2px !important;
            font-size: 0.85rem !important;
        }
    }
}


.selectfield-default-menuitem-avatar {
    width: 20px !important;
    height: 20px !important;
    margin-right: 12px !important;
}
