
@import '../../assets/scss/variables';

.extrascorecard-box {
    background-color: $color-grey-1 !important;
    border-radius: $borderradius-default !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &.expired {
        opacity: 0.7;
    }

    .icon-invalid {
        position: absolute;
        cursor: pointer;
        top: 13px;
        right: 12px;
        font-size: 18px;
        border-radius: 20px;
    }

    &.small-icon-invalid {
        .icon-invalid {
            top: 5px;
            font-size: 14px;
        }
    }

    .extrascorecard-title {
        border-top-left-radius: $borderradius-default !important;
        border-top-right-radius: $borderradius-default !important;
    }

    .extrascorecard-informations {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
    }

    .MuiButton-root {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
}


.infoextrascore-tooltip {
    border-radius: $borderradius-default !important;
    padding: 0px !important;
    width: 320px !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3) !important;

    .infoextrascore-title {
        text-align: center !important;
        border-top-left-radius: $borderradius-default !important;
        border-top-right-radius: $borderradius-default !important;
    }
}