@import '../../assets/scss/variables';

.questionnairesubmissionstatus-container {
    background-color: #fff !important;
    padding: 24px 20px;
    border-radius: $borderradius-default !important;

    .questionnairesubmissionstatus-details {
        .questionnairesubmissionstatus-result {
            padding: 4px;
            
            &:not(:first-child) {
                border-left: 1px solid #e0e0e0;
            }


            &.grid-statistic {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .box-percent {
                    height: 40px;
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    background-color: $color-grey-3 !important;
                    border-radius: 4px;
                    position: relative;
                    display: flex;
                    color: #fff;
                    font-size: 14px;
    
                    .box-sent, .box-pending {
                        height: 100%;
                        max-width: 100%;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
    
                        &.box-sent {
                            background-color: $color-green;
                        }
    
                        &.box-pending {
                            color: #000;
                            flex: 1;
                        }
                    }
                }

                .box-legend {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    margin-top: 20px;

                    .text-legend-item {
                        display: flex;
                        align-items: center;
                        font-size: 14px;

                        .indicator {
                            display: inline-block;
                            width: 14px;
                            height: 14px;
                            border-radius: 20px;
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: $screen-size-lg) {
    .questionnairesubmissionstatus-container {
        .questionnairesubmissionstatus-details {
            .questionnairesubmissionstatus-result {
                width: 100% !important;
                max-width: unset !important;
                flex-basis: 100% !important;
                padding: 16px 0px;
                
                &:not(:first-child) {
                    border-left: none;
                    border-top: 1px solid #e0e0e0;
                }


                &.grid-statistic {
                    padding-top: 32px;
                }
            }
        }
    }
}