
@import '../../assets/scss/variables';

.dialog-default {
    background-color: $color-grey-1 !important;
    border-radius: $borderradius-default !important;

    &.dialog-extrascores {
        .dialogtitle-default {
            text-align: center;
            background-color: $color-primary !important;
        }

        .dialogcontent-default {
            padding: 28px 40px 0px 40px !important;
        }

        .dialogactions-default {
            padding: 28px 40px !important;
            justify-content: space-between !important;
        }
    }

    &.dialog-rulesandlicenses {
        .dialogtitle-default {
            text-align: center;
            background-color: $color-primary !important;
        }

        .dialogcontent-default {
            padding: 28px 40px 0px 40px !important;
        }

        .dialogactions-default {
            padding: 28px 40px !important;
            justify-content: space-between !important;
        }
    }
}