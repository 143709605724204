@import '../../assets/scss/variables';

.satisfaction-container {
    background-color: #fff !important;
    padding: 24px 20px;
    border-radius: $borderradius-default !important;

    .satisfaction-average {
        background-color: #f5f5f5;
        border-radius: $borderradius-default !important;
        padding: 32px 24px;
        display: flex;
        align-items: center;

        .text-average {
            margin-right: 8px;
            font-weight: 600;
            color: $color-yellow !important;
        }

        .text-description {
            margin-left: 20px;
        }
    }

    .satisfaction-details {
        .satisfaction-result {
            padding: 4px;
            
            &:not(:first-child) {
                border-left: 1px solid #e0e0e0;
            }
        }
    }
}


@media screen and (max-width: $screen-size-lg) {
    .satisfaction-container {
        .satisfaction-average {
            flex-direction: column;

            .text-average {
                margin-right: 0px;
                margin-bottom: 4px;
            }

            .text-description {
                margin-left: 0px;
                margin-top: 8px;
                text-align: center;
            }
        }
    
        .satisfaction-details {
            .satisfaction-result {
                width: 100% !important;
                max-width: unset !important;
                flex-basis: 100% !important;
                padding: 16px 0px;
                
                &:not(:first-child) {
                    border-left: none;
                    border-top: 1px solid #e0e0e0;
                }
            }
        }
    }
}