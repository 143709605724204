
@import '../../assets/scss/variables';

.detailedassessment-container {
    background-color: $color-primary !important;
    padding: 24px 20px;
    border-radius: $borderradius-default !important;

    &.background-bandag {
        background-color: $color-bandag !important;
    }

    .categorie-item {
        border-radius: $borderradius-default !important;
        padding: 20px 8px;
        text-align: center;
        width: calc(20% - 16px);
        background-color: $component-default-background-color-grey-3 !important;
        margin: 8px;

        &:first-child {
            background-color: #fff !important;
        }
    }
}