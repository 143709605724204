/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "./custom/functions";
@import "./custom/variables";
@import "./custom/mixins";

// Bootstrap (4.1.3) components

@import "./bootstrap/root";
@import "./bootstrap/reboot";
@import "./bootstrap/type";
@import "./bootstrap/images";
@import "./bootstrap/code";
@import "./bootstrap/grid";
@import "./bootstrap/tables";
@import "./bootstrap/forms";
@import "./bootstrap/buttons";
@import "./bootstrap/transitions";
@import "./bootstrap/dropdown";
@import "./bootstrap/button-group";
@import "./bootstrap/input-group";
@import "./bootstrap/custom-forms";
@import "./bootstrap/nav";
@import "./bootstrap/navbar";
@import "./bootstrap/card";
@import "./bootstrap/breadcrumb";
@import "./bootstrap/pagination";
@import "./bootstrap/badge";
@import "./bootstrap/jumbotron";
@import "./bootstrap/alert";
@import "./bootstrap/progress";
@import "./bootstrap/media";
@import "./bootstrap/list-group";
@import "./bootstrap/close";
@import "./bootstrap/modal";
@import "./bootstrap/tooltip";
@import "./bootstrap/popover";
@import "./bootstrap/carousel";
@import "./bootstrap_new/spinners";
@import "./bootstrap/utilities";
@import "./bootstrap/print";

// Argon utilities and components

@import "./custom/reboot";
@import "./custom/utilities";
@import "./custom/components";

// Vendor (Plugins)

@import "./custom/vendors";

// React differences
@import "./react/react-differences";

@import "./bridgestone";

@import "./globals";
