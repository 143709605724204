
@import '../variables';


.alert-error {
    position: fixed;
    top: 28px;
    right: 28px;
    z-index: 9999;
    max-width: 400px;
}


.container-sacpersonal {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: $screen-sacpersonal-header-height 1fr $screen-sacpersonal-footer-height;
    grid-template-areas: 'form-header' 'form-content' 'form-footer';
    height: 100vh;
    background: $screen-sacpersonal-body-background;

    .container-header {
        grid-area: form-header;
        display: flex;
        align-items: flex-start;
        background: $color-primary;
        padding: 20px 28px 52px 28px;
    }

    .container-content {
        grid-area: form-content;
        display: flex;
        justify-content: center;
        padding: 0px 8px 0px 8px;

        .paper-form {
            position: relative;
            top: -44px;
            height: 100%;
            padding: 12px 20px;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 52px 1fr 76px;
            grid-template-areas: "form-header" "form-content" "form-footer";
            border-radius: $screen-sacpersonal-paper-border-radius;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);

            .container-form-header {
                padding: 0px 16px;
                grid-area: form-header;
                display: flex;
                align-items: center;

                .header-info {
                    display: flex;
                    align-items: center;

                    .text-title {
                        font-weight: 600;
                    }
                }
            }

            .container-form-content {
                grid-area: form-content;
                overflow-y: auto;
                overflow-x: hidden;

                .container-form-fields {
                    padding: 0px 16px;
                }
            }

            .container-form-footer {
                grid-area: form-footer;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                padding: 12px 12px 0px 12px;
            }
        }
    }

    .container-footer {
        grid-area: form-footer;
        background-color: $screen-sacpersonal-footer-background;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container-surveycompleted {
        grid-area: form-content;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0px 20px;

        .image-completed {
            display: block;
            font-size: 76px;
            color: $color-green;
            margin-bottom: 12px;
        }

        .text-title {
            color: $color-green;
        }
    }
}


@media screen and (max-width: $screen-size-md) {
    .alert-error {
        top: 8px;
        right: 8px;
        max-width: unset;
        width: calc(100% - 16px);
    }
}