@import '../../assets/scss/variables';

.metrics-container {
    background-color: $color-primary !important;
    padding: 24px 20px;
    border-radius: $borderradius-default !important;
    align-items: stretch;

    .metric-grid {
        padding: 12px;
        flex: 1 1 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:not(:first-child) {
            border-left: 1px solid #fff;
        }

        .metric-value {
            line-height: 28px;
            min-height: 52px;
            display: flex;
            align-items: center;
        }
    }
}


@media screen and (max-width: $screen-size-lg) {
    .metrics-container {
        .metric-grid {
            width: 100%;
            flex: unset;
            padding: 16px 12px;
            text-align: center;
    
            &:not(:first-child) {
                border-left: none;
                border-top: 1px solid #fff;
            }

            .metric-value {
                justify-content: center;
            }
        }
    }
}