
@import '../../../assets/scss/variables';

.youremblems-container {
    .youremblemcard-item {
        width: 20% !important;
        border: 6px solid #fff;
        align-self: stretch;
    }
}


@media screen and (max-width: $screen-size-lg) {
    .youremblems-container {
        .youremblemcard-item {
            width: 50% !important;
        }
    }
}