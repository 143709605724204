
@import '../../../assets/scss/variables';

.rulesandlicenses-container {
    .rulesandlicensecard-info-container {
        border-radius: $borderradius-default !important;
        background-color: $color-grey-1 !important;
        align-items: center;

        .rulesandlicensecard-info {
            background-color: #fff;
            text-align: center;
            border-radius: 16px;
            border: 6px solid $color-grey-1;
            padding: 12px 8px !important;
            align-self: stretch;
        }
    }

    .rulesandlicensecard-list-container {
        .rulesandlicensecard-item {
            width: 20% !important;
            border: 6px solid #fff;
            align-self: stretch;
        }
    }
}


@media screen and (max-width: 1400px) {
    .rulesandlicenses-container {
        .rulesandlicensecard-info-container {
            .rulesandlicensecard-info {
                .MuiButton-root {
                    min-width: unset !important;
                    padding-left: unset;
                    padding-right: unset;
                }
            }
        }

        .rulesandlicensecard-list-container {
            .rulesandlicensecard-item {
                width: 25% !important;
            }
        }
    }
}


@media screen and (max-width: $screen-size-lg) {
    .rulesandlicenses-container {
        .rulesandlicensecard-list-container {
            .rulesandlicensecard-item {
                width: 50% !important;
            }
        }
    }
}