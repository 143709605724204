@import '../../assets/scss/variables';

.nps-container {
    background-color: #fff !important;
    padding: 24px 20px;
    border-radius: $borderradius-default !important;

    .nps-details {
        .nps-result {
            padding: 4px;
            
            &:not(:first-child) {
                border-left: 1px solid #e0e0e0;
            }
        }
    }
}

.infonps-tooltip {
    border-radius: $borderradius-default !important;
    padding: 0px !important;
    width: 252px !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3) !important;

    .infonps-title {
        text-align: center !important;
        border-top-left-radius: $borderradius-default !important;
        border-top-right-radius: $borderradius-default !important;
    }
}


@media screen and (max-width: $screen-size-lg) {
    .nps-container {
        .nps-details {
            .nps-result {
                width: 100% !important;
                max-width: unset !important;
                flex-basis: 100% !important;
                padding: 16px 0px;
                
                &:not(:first-child) {
                    border-left: none;
                    border-top: 1px solid #e0e0e0;
                }
            }
        }
    }
}